<template>
   <v-app>
    <div>
      <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
    </div>
     <div class="row">
      <div class="col-md-12">
        <div class="mb-8 mt-8 text-center">
          <h1>New PPE Request</h1>
        </div>
        <v-form ref="ppeRequest" v-model="valid">
          <v-row no-gutters>
            <v-col class="mb-6 col-md-5 mx-auto">
              <v-btn class="btn-style justify-start text-style" @click.prevent="perSearch = true"  width="100%" height="38" outlined >  <v-icon left>mdi-magnify</v-icon> Personnel</v-btn>
            </v-col>
            <!-- selected personnel list -->
            <v-col v-if="personnel.length > 0" class="col-md-11 mx-auto mb-6">
               <v-simple-table>
                <template v-slot:default>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Personnel</th>
                        <th>Job Title</th>
                        <th>Location</th>
                        <th>Status</th>
                        <th>Comment</th>
                        <th v-for="item in headers" :key="item.headerName">{{item.headerName}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="request in requests" :key="request.employeeId">
                        <td>
                          <v-icon small @click="removePersonnel(request)">mdi-delete</v-icon>
                        </td>
                        <td style="min-width: 1%; white-space: nowrap;">
                          {{request.employeeName}}
                        </td>
                        <td>
                          {{request.jobTitle}}
                        </td>
                        <td>
                          {{request.locationName}}
                        </td>
                        <td>
                          {{request.status}}
                        </td>
                        <td>
                          <input type="text" class="input" v-model="request.comment" />
                        </td>
                        <td v-for="(item, index) in request.requestItems"
                            :key="item.typeId">
                          <input type="number" class="quantity-input" v-model="request.requestItems[index].quantity" />
                        </td>
                      </tr>
                    </tbody>
                </template>
            </v-simple-table>
             <!-- <v-data-table
             :headers="personnelHeaders"
             :items="personnel"
             >
              <template v-slot:item.comments="{ item }">
                  <input
                    type="text"
                    class="input"
                    v-model="item.comment"
                   >
              </template>

              <template v-slot:item.actions="{ item }">

                <v-icon
                  small
                  @click="removePersonnel(item)"
                >
                  mdi-delete
                </v-icon>
              </template>

             </v-data-table> -->
            </v-col>
          </v-row>

        </v-form>
      </div>

    </div>
   <v-row v-if="personnel.length > 0">
      <v-col cols="11" class="d-flex justify-end">
        <Button
          :label="'Submit'"
          :btnType="'Submit'"
          @onClick="dialog = true"
          :disabled="!valid"
          :isLoading="isSavingRequest"
          class="mr-2"
        />

        <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
      </v-col>
    </v-row>
     <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isSavingRequest" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
     <PersonnelSearch :dialog="perSearch" @selectPer="selectPersonnel"  @close="perSearch = false"/>
   </v-app>
</template>
<script>
import Button from '@/components/ui/form/Button.vue'
import { employeeService, requestService } from '@/services'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { CREATE_REQUEST } from '@/store/action-type'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
export default {
  components: {
    Button,
    PersonnelSearch,
    ConfirmationDialog
  },
  data () {
    return {
      alertMessage: '',
      alert: false,
      alertType: '',
      perSearch: false,
      dialog: false,
      Ppetype: null,
      selected: [],
      search: '',
      valid: false,
      isSavingRequest: false,
      quantity: '',
      personnelSearch: [],
      ppeTypes: [],
      searchHeaders: [
        {
          text: 'FullName',
          value: 'name'
        },
        {
          text: 'Location',
          value: 'location'
        },
        {
          text: 'Job Title',
          value: 'jobTitle'
        },
        {
          text: 'email',
          value: 'email'
        }
      ],
      personnelHeaders: [
        {
          text: 'Name',
          value: 'name'
        }, {
          text: 'PPEType',
          value: 'PPEType'
        },
        { text: 'Comment', value: 'comments' },
        { text: 'Actions', value: 'actions' }
      ],
      personnel: [],
      headers: [],
      requests: []
    }
  },
  watch: {
    personnel: function (personnels) {
      this.requests = []

      personnels.forEach(personnel => {
        const items = []
        this.ppeTypes.forEach(type => {
          items.push({
            typeName: type.name,
            typeId: type.id,
            quantity: 0
          })
        })

        this.requests.push({
          employeeName: personnel.name,
          employeeId: personnel.id,
          comment: '',
          requestItems: items,
          locationName: personnel.location,
          jobTitle: personnel.jobTitle
        })
      })
    }
  },
  methods: {
    openPersonnelSearchModal () {
      this.$refs.personnelSearch.openModal()
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    closePersonnelSearchModal () {
      this.personnelSearch = []
      this.$refs.personnelSearch.closeModal()
    },
    personnelSearchFunction () {
      if (this.search === '') {
        this.personnelSearch = []
        return
      }

      employeeService.searchPersonnel(this.search).then(result => {
        this.personnelSearch = result.data
      })
    },
    selectPersonnel (item) {
      let index = null
      for (let i = 0; i < this.personnel.length; i++) {
        if (this.personnel[i].id === item.id) {
          index = i
          break
        }
      }
      if (index === 0) {
        return
      }

      this.personnel.push({ name: `${item.name}`, id: `${item.id}` })
      // this.personnel.push({ name: `${item.name}`, PpetypeId: this.Ppetype.id, PPEType: this.Ppetype.name, id: item.id })
      this.search = ''
    },
    removePersonnel (item) {
      let index = null
      for (let i = 0; i < this.personnel.length; i++) {
        if (this.personnel[i].id === item.id) {
          index = i
          break
        }
      }
      this.personnel.splice(index, 1)
    },
    onSave () {
      let data = []
      this.isSavingRequest = true
      this.requests.map(request => {
        const items = request.requestItems
        const ppeItems = []
        items.forEach(item => {
          ppeItems.push({
            PPETypeId: item.typeId,
            Narration: item.quantity

          })
        })

        const personnelPPe = {
          EmployeeId: request.employeeId,
          RequestTypeId: 4,
          PPEs: ppeItems,
          Comments: request.comment
        }

        data.push(personnelPPe)
        return data
      })

      this.$store.dispatch(CREATE_REQUEST, { type: 'PPE', data }).then(() => {
        this.showAlertMessage('Request creation successful', 'success')
      }).catch(() => {
        this.showAlertMessage('Request creation failed', 'error')
      }).finally(() => {
        this.isSavingRequest = false
        this.dialog = false
        this.onReset()
      })
    },
    onReset () {
      this.search = ''
      this.personnel = []
      this.$refs.ppeRequest.reset()
    },
    createRequestHeader () {
      this.ppeTypes.forEach(type => {
        this.headers.push({
          headerName: type.name,
          headerId: type.id
        })
      })
    },
    createRequestRows () {
      console.log('personnel', this.personnel)
    }
  },
  mounted () {
    requestService.getPpeTypes().then(result => {
      this.ppeTypes = result.data
      this.createRequestHeader()
    }).catch(error => {
      console.log(error)
    })
  }
}
</script>
<style scoped>
.quantity-input {
  border: 1px rgb(156, 154, 154) solid;
  width: 60px;
  padding: 5px 0px;
  background: #fff;
  text-align:center;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.btn-style{
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
}
.text-style{
  text-transform: capitalize;
}
.input{
  border:1px solid grey;
  height: 30px;
  background: #fff;
}
</style>
